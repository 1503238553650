import { urls } from "@/urls";

export const embedRoutes = {
  async lazy() {
    const { Embed } = await import("./Embed");
    return { Component: Embed };
  },
  children: [
    {
      path: urls.embed.home,
      async lazy() {
        const { Home } = await import("./Home");
        return { Component: Home };
      },
    },
    {
      path: urls.embed.chat,
      async lazy() {
        const { Chat } = await import("./Chat");
        return { Component: Chat };
      },
    },
  ],
};
