import { urls } from "@/urls";

export const botRoutes = {
  async lazy() {
    const { Root } = await import("./Root");
    return { Component: Root };
  },
  children: [
    {
      path: urls.design_genie.home,
      async lazy() {
        const { Home } = await import("./layout/Home");
        return { Component: Home };
      },
    },
    {
      path: urls.design_genie.chat,
      async lazy() {
        const { Chat } = await import("./layout/Chat");
        return { Component: Chat };
      },
    },
    {
      path: urls.design_genie.reference_image,
      async lazy() {
        const { ReferenceImage } = await import("./layout/ReferenceImage");
        return { Component: ReferenceImage };
      },
    },
    {
      path: urls.design_genie.design_brief,
      async lazy() {
        const { DesignBrief } = await import("./layout/DesignBrief");
        return { Component: DesignBrief };
      },
    },
    {
      path: urls.design_genie.book,
      async lazy() {
        const { Book } = await import("./layout/Book");
        return { Component: Book };
      },
    },
    {
      path: urls.design_genie.thank_you,
      async lazy() {
        const { ThankYou } = await import("./layout/ThankYou");
        return { Component: ThankYou };
      },
    },
  ],
};
