export const Language = {
  get: () => {
    const language =
      window.localStorage.getItem("language") ||
      window.navigator.language ||
      "en-US";
    if (language.startsWith("en")) {
      window.localStorage.setItem("language", "en-US");
    } else if (language.startsWith("zh") || language == "ch") {
      window.localStorage.setItem("language", "zh-TW");
    } else if (language.startsWith("ko") || language == "kr") {
      window.localStorage.setItem("language", "ko-KR");
    } else {
      window.localStorage.setItem("language", "en-US");
    }

    return (
      window.localStorage.getItem("language") ||
      window.navigator.language ||
      "en-US"
    );
  },
  set: (intl: string) => {
    window.localStorage.setItem("language", intl);
  },
  shortenToIntl: (shorten: string) => {
    if (shorten == "en") {
      return "en-US";
    } else if (shorten == "ch") {
      return "zh-TW";
    } else if (shorten == "kr") {
      return "ko-KR";
    }

    return "en-US";
  },
  intlToShorten: (intl: string | undefined) => {
    if (!intl) return "en";

    if (intl.startsWith("en")) {
      return "en";
    } else if (intl.startsWith("zh")) {
      return "ch";
    } else if (intl.startsWith("ko")) {
      return "kr";
    }

    return "en";
  },
  getString: () => {
    const language =
      window.localStorage.getItem("language") ||
      window.navigator.language ||
      "en-US";
    if (language == "en-US") {
      return "English";
    } else if (language == "zh-TW") {
      return "繁體中文";
    } else if (language == "ko-KR") {
      return "한국어";
    }

    return "";
  },
};
