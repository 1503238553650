import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import "./i18n";
import * as Sentry from "@sentry/react";

window.addEventListener("vite:preloadError", (_event) => {
  console.log("vite:preloadError");
  window.location.reload();
});

Sentry.init({
  dsn:
    import.meta.env.MODE != "development"
      ? "https://4ede5672f6fb310c26944ca83506b7ad@o4507666089771008.ingest.us.sentry.io/4507666710921216"
      : "",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [new RegExp(import.meta.env.VITE_BACKEND_ENDPOINT)],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
