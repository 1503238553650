import { urls } from "@/urls";

export const sharedAdminRoutes = {
  async lazy() {
    const { SharedRoot } = await import("./SharedRoot");
    return { Component: SharedRoot };
  },
  children: [
    {
      path: urls.admin.shared_overview,
      async lazy() {
        const { ClientRequestSharedOverview: SharedOverview } = await import(
          "./layout/ClientRequestSharedOverview"
        );
        return { Component: SharedOverview };
      },
    },
    {
      path: urls.admin.shared_suggestions,
      async lazy() {
        const { ClientRequestSharedSuggestions: SharedSuggestions } =
          await import("./layout/ClientRequestSharedSuggestions");
        return { Component: SharedSuggestions };
      },
    },
    {
      path: urls.admin.shared_history,
      async lazy() {
        const { SharedHistory } = await import(
          "./layout/ClientRequestSharedHistory"
        );
        return { Component: SharedHistory };
      },
    },
    {
      path: urls.admin.demo_shared_overview,
      async lazy() {
        const { ClientRequestDemoSharedOverview } = await import(
          "./layout/ClientRequestDemoSharedOverview"
        );
        return { Component: ClientRequestDemoSharedOverview };
      },
    },
    {
      path: urls.admin.demo_shared_history,
      async lazy() {
        const { ClientRequestDemoSharedHistory } = await import(
          "./layout/ClientRequestDemoSharedHistory"
        );
        return { Component: ClientRequestDemoSharedHistory };
      },
    },
    {
      path: urls.admin.demo_shared_suggestions,
      async lazy() {
        const { ClientRequestDemoSharedSuggestions } = await import(
          "./layout/ClientRequestDemoSharedSuggestions"
        );
        return { Component: ClientRequestDemoSharedSuggestions };
      },
    },
  ],
};
