export const urls = {
  login: "/login",
  registration: "/registration",
  plan: "/choosing_plan",
  demo: {
    chat: "/genie_demo/chat",
    design_brief: "/genie_demo/design_brief/:identifyKey",
    reference_image: "/genie_demo/reference_image/:identifyKey",
  },
  design_genie: {
    home: "/genie/:userLinkWords",
    chat: "/genie/:userLinkWords/chat",
    reference_image: "/genie/:userLinkWords/reference_image/:identifyKey",
    design_brief: "/genie/:userLinkWords/design_brief/:identifyKey",
    book: "/genie/book",
    thank_you: "/genie/:userLinkWords/thank_you",
  },
  admin: {
    dashboard: "/client_request",
    client: "/client_request/:clientId",
    shared_overview: "/client_request/:identifyKey/overview",
    shared_suggestions: "/client_request/:identifyKey/suggestions",
    shared_history: "/client_request/:identifyKey/history",
    account: "/account",
    account_info_edit: "/account/info/edit",
    account_password_edit: "/account/password/edit",
    demo: "/client_request_demo",
    demo_shared_overview: "/client_request_demo/overview",
    demo_shared_suggestions: "/client_request_demo/suggestions",
    demo_shared_history: "/client_request_demo/history",
  },
  embed: {
    home: "/genie-embed/:userLinkWords/",
    chat: "/genie-embed/:userLinkWords/chat",
  },
  moodBoard: "/mood_board/:userLinkWords",
};
