import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const ErrorPage = () => {
  const { t } = useTranslation("general", { keyPrefix: "general.error" });
  const error = useRouteError();

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      if (!(error instanceof Error)) return;
      if (
        error.message.includes("Failed to fetch dynamically imported module") ||
        error.message.includes("Importing a module script failed")
      ) {
        console.log("Import error");
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <div className="flex flex-col items-center justify-center h-screen gap-4">
      <div className="text-[64px]">{t("oops")}</div>
      <div className="text-[32px]">
        {t("errorOccurred")} <br />
        {t("reloadOrContact")}
      </div>
      <div className="text-[24px]">
        <i>
          Error:{" "}
          {(error as Error)?.message ||
            (error as { statusText?: string })?.statusText}
        </i>
      </div>
    </div>
  );
};
