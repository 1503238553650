import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { adminRoutes } from "./pages/admin/Route";
import { botRoutes } from "./pages/bot/Route";
import {
  loginRoutes,
  planRoutes,
  registrationRoutes,
} from "./pages/login/Route";
import { demoRoutes } from "./pages/demoBot/Route";
import AuthProvider from "./provider/AuthProvider";
import { urls } from "./urls";
import { ErrorPage } from "./pages/error/ErrorPage";
import { sharedAdminRoutes } from "./pages/admin/SharedRoute";
import { embedRoutes } from "./pages/embedBot/Route";
import { moodBoardRoutes } from "./pages/moodBoard/Route";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-TTZMPHTS",
};

TagManager.initialize(tagManagerArgs);

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Navigate to={urls.login} replace />,
      },
      loginRoutes,
      registrationRoutes,
      planRoutes,
      botRoutes,
      adminRoutes,
      embedRoutes,
      moodBoardRoutes,
    ],
  },
  sharedAdminRoutes,
  demoRoutes,
]);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}

export default App;
