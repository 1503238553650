import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { RegisterData, LoginData, UserAPI } from "@/service/user";
import { urls } from "@/urls";
import { retryHandler } from "@/utils/error";
import { useMutation } from "@tanstack/react-query";
import { AlertCircle } from "lucide-react";
import { useContext, createContext, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

type Context = {
  token: string;
  login: (data: LoginData) => Promise<boolean>;
  logout: () => void;
  register: (data: RegisterData) => Promise<boolean>;
  checkAuth: () => boolean;
};

const AuthContext = createContext<Context>({
  token: "",
  login: () => Promise.resolve(false),
  logout: () => {},
  register: () => Promise.resolve(false),
  checkAuth: () => false,
});

const AuthProvider = () => {
  const [isLoginError, setIsLoginError] = useState(false);
  const [isRegisterError, setIsRegisterError] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const loginMutation = useMutation({
    mutationFn: (data: LoginData) => UserAPI.login(data),
    retry: retryHandler,
  });

  const registerMutation = useMutation({
    mutationFn: (data: RegisterData) => UserAPI.register(data),
    retry: retryHandler,
  });

  const login = async (data: LoginData): Promise<boolean> => {
    try {
      const token = await loginMutation.mutateAsync(data);
      if (token) {
        localStorage.setItem("site", token);
        setToken(token);
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      setIsLoginError(true);
      setTimeout(() => setIsLoginError(false), 3000);
      return false;
    }
  };

  const register = async (data: RegisterData) => {
    try {
      const token = await registerMutation.mutateAsync(data);
      if (token) {
        localStorage.setItem("site", token);
        setToken(token);
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      setIsRegisterError(true);
      setTimeout(() => setIsRegisterError(false), 3000);
      return false;
    }
  };

  const logout = () => {
    setToken("");
    localStorage.removeItem("site");
    navigate(urls.login);
  };

  const checkAuth = () => {
    if (token != "" || localStorage.getItem("site")) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <AuthContext.Provider
      value={{
        token,
        login: async (data: LoginData): Promise<boolean> => {
          const result = await login(data);
          return result ?? false;
        },
        register: async (data: RegisterData): Promise<boolean> => {
          const result = await register(data);
          return result ?? false;
        },
        logout,
        checkAuth,
      }}
    >
      {isLoginError && (
        <div className="fixed flex justify-center w-full top-8">
          <Alert variant="destructive" className="w-auto bg-white">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              Email or Password is incorrect. Please try again.
            </AlertDescription>
          </Alert>
        </div>
      )}
      {isRegisterError && (
        <div className="fixed flex justify-center w-full top-8">
          <Alert variant="destructive" className="w-auto bg-white">
            <AlertCircle className="h-4 w-4" />
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>
              Genie Name, Email or Password is incorrect. Please try again.
            </AlertDescription>
          </Alert>
        </div>
      )}
      <Outlet />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
