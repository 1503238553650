export class FetchError extends Error {
  constructor(
    public res: Response,
    message?: string,
  ) {
    super(message);
  }
}

export const retryHandler = (
  failureCount: number,
  error: FetchError | Error,
) => {
  if (error instanceof FetchError) {
    if (error.res.status >= 500 && failureCount < 3) {
      return true;
    } else {
      return false;
    }
  }

  if (failureCount < 3) return true;
  return false;
};
