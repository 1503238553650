import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import adminEn from "@/locales/en/admin.json";
import adminCh from "@/locales/ch/admin.json";
import adminKr from "@/locales/kr/admin.json";
import botEn from "@/locales/en/bot.json";
import botCh from "@/locales/ch/bot.json";
import botKr from "@/locales/kr/bot.json";
import loginEn from "@/locales/en/login.json";
import loginCh from "@/locales/ch/login.json";
import loginKr from "@/locales/kr/login.json";
import generalEn from "@/locales/en/general.json";
import generalCh from "@/locales/ch/general.json";
import generalKr from "@/locales/kr/general.json";
import { Language } from "./utils/language";

const resources = {
  en: {
    admin: adminEn,
    bot: botEn,
    login: loginEn,
    general: generalEn,
  },
  ch: {
    admin: adminCh,
    bot: botCh,
    login: loginCh,
    general: generalCh,
  },
  kr: {
    admin: adminKr,
    bot: botKr,
    login: loginKr,
    general: generalKr,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: Language.intlToShorten(Language.get()),
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
